const data = {
	"contact": {
		email: "mike@readable.app",
		linkedIn: { 
			url: "https://www.linkedin.com/in/mike-donnelly-dev/",
			title: "mike-donnelly-dev",
		},
		profilePic: {
			path: "/images/common/profile-pic-pop.png",
			alt: "A well presented fellow",
		}
	},
	"home": {
		"name": "Mike Donnelly",
		"location": "Edinburgh, UK",
		"title": "Full stack developer",
		"cvPath": "/docs/Mike Donnelly – Full stack developer.pdf",


		"subsections": [
			{
				"id": "experience",
				"title": "Experience",
				"icon": {
					"src": "/images/home/button-medal2.png",
				},
				"summary": "I've been making high quality digital products for 15+ years, entertaining and educating millions of users, learners and players.",
				"detail": `
					<p>
						As cofounder of three companies I've been lucky enough 
						to work on award winning projects with some prestigious organisations, including BBC Learning, 
						BBC Drama & Entertainment, Amazon, Cambridge Dictionaries, as well as acclaimed arts orgs 
						like Arika and The 8½ Foundation.
						</p>
						<p>
						In all my projects I put my skills diligently to work, aiming to create positive change 
						in the world and have fun trying.
						</p>
						<p>
						Career highlight so far: a series of educational interactives we made for the BBC which 
						achieved the highest Net Promoter score on bbc.co.uk for 18 consecutive months!
						{readLess}
					</p>
				`,
				"link": "Selected projects",

			},

			{

				"id": "skills",
				"title": "Skills",
				"icon": {
					"src": "/images/home/button-skills.png",
				},
				"summary": "Most of my working day is spent developing mobile and web apps (front & back ends) in JavaScript and working with its huge armoury of tools.",
				"detail": `
					<p>
						I'm also constantly researching new technologies to suit the latest project. 
						Currently I'm looking into machine learning in computational linguistics{icon-sweat}{readLess}
					</p>
				`,
				"link": "Detailed skills rundown",

			}
		]
	},

	skills: {
		groups: [
			{
				id: "languages",
				title: "Languages",
				icon: "skills-code-simple.png",
				items: [
					{title: "JavaScript", subtitle: "Including TypeScript, Flow", level: "expert"},
					{title: "Node.js", subtitle: "", level: "expert"},
					{title: "HTML", subtitle: ""},
					{title: "CSS, SCSS", subtitle: ""},
					{title: "PHP", subtitle: ""},
					{title: "Python", subtitle: "", level: "learner", titleNote: "(learning)"},
				]
			},

			{
				id: "platforms",
				title: "Platforms & Stacks",
				icon: "skills-structure.png",
				items: [
					{title: "Web", subtitle: "", level: ""},
					{title: "Android", subtitle: "", level: ""},
					{title: "iOS", subtitle: "", level: ""},
					{title: "MERN", subtitle: "", level: ""},
					{title: "Jamstack", subtitle: "", level: ""},
					{title: "LAMP", subtitle: "", level: ""},
				]
			},

			{
				id: "react",
				title: "React development",
				icon: "skills-applications.png",
				items: [
					{title: "React", subtitle: "Vite, CRA", level: ""},
					{title: "React Native", subtitle: "", level: ""},
					{title: "React Admin", subtitle: "", level: ""},
					// {title: "Appium", subtitle: "", level: ""},
					// {title: "Class & function architectures", subtitle: "", level: ""},
					{title: "Bundlers", subtitle: "Webpack, Parcel, Gulp, etc.", level: ""},
					{title: "Next.js", subtitle: "", level: "learner", titleNote: "(learning)"},
				]
			},

			{
				id: "databases",
				title: "Databases",
				icon: "skills-treasure.png",
				items: [
					{title: "MongoDB", subtitle: "", level: ""},
					{title: "SQL", subtitle: "", level: ""},
					{title: "BigQuery", subtitle: "", level: ""},
					{title: "Solr", subtitle: "", level: ""},
					{title: "CouchDB", subtitle: "", level: ""},
					{title: "Redis", subtitle: "", level: ""},
					{title: "GraphQL", subtitle: "", level: "learner", titleNote: "(learning)"},
				]
			},

			{
				id: "productivity",
				title: "Productivity<br/>&amp;&nbsp;Collab.",
				icon: "skills-productivity.png",
				items: [
					{title: "Jira", subtitle: "", level: ""},
					{title: "Trello", subtitle: "", level: ""},
					{title: "Miro", subtitle: "", level: ""},
					{title: "Agile development", subtitle: "Scrum, Kanban", level: ""},
					{title: "Plain old Google Sheets", subtitle: "", level: ""},
					{title: "Figma", subtitle: "", level: "learner", titleNote: "(learning)"},
				]
			},

			{
				id: "services",
				title: "Popular services",
				icon: "skills-api.png",
				items: [
					{title: "Google Analytics", subtitle: "", level: ""},
					{title: "GCP", subtitle: "", level: ""},
					{title: "Firebase", subtitle: "", level: ""},
					{title: "Admob", subtitle: "", level: ""},
					{title: "AWS", subtitle: "S3, Polly", level: ""},
					{title: "Digital Ocean", subtitle: "Apps, Droplets", level: ""},
					{title: "Netlify", subtitle: "", level: ""},
					{title: "IAPHUB", subtitle: "", level: ""},
					{title: "Bugsnag", subtitle: "", level: ""},
				]
			},

			{
				id: "devtools",
				title: "Development tools",
				icon: "skills-anvil.png",
				items: [
					{title: "Unix terminal", subtitle: "SSH, rsync, curl, apt, etc.", level: ""},
					{title: "Git, GitHub", subtitle: "", level: ""},
					{title: "Docker", subtitle: "", level: ""},
					{title: "Regex", subtitle: "", level: ""},
				]
			},

			// {
			// 	id: "",
			// 	title: "",
			// 	items: [
			// 		{title: "", subtitle: "", level: ""},
			// 	]
			// },
		]
	},
	"projects": [

		{
			"id": "readable",
			"title": "Readable",
			"badges": ["british-council-elton-finalist", "mobile-app"],
			"summary": "The world’s best stories made easy to read and free for every English learner", 
			"description": `
			<p>
			I'm a cofounder and tech lead at <em>Readable</em>. I spend most of my time working on...
			<ul class="responsibilities">
				<li>UI and business logic dev<br/><strong>React Native, JavaScript</strong></li>
				<li>Database design<br/><strong>MongoDB, SQLite, CouchDB</strong></li>
				<li>Building content creation tools<br/><strong>Node.js, react-admin, Symfony</strong></li>
				<li>Building analytics tools<br/><strong>Firebase, BigQuery, Data Studio</strong></li>
				<li>Computational linguistics<br/><strong>Python, Docker</strong></li>
			</ul>
			</p>
			<p>
			...and constant research! 
			<ul class="awards">
				<li>Partnered with leading education publisher <strong>Macmillan</strong></li>
				<li>Finalist in the <strong>ELTons</strong> (2020), the preeminent international English language teaching awards</li>
				<li>Member of the Educate accelerator at the world’s #1 educational research institution, <strong>UCL’s Institute of Education</strong></li>
				<li><strong>1M+ users</strong> and a Google Play rating of <strong>4.8 stars</strong> globally (5 stars in Germany, France, Brazil)</li>
			</ul>
			</p>
			`,
			"links": [
				{"name": "www.readable.app", "url": "https://readable.app"}
			],
			"tech": "JavaScript, React Native, Android, iOS, DB design, Firebase, Node.js, Python, Agile",

			"images": [
				{type: "image", path: "/images/experience/gallery/readable/0.png", alt: "The Readable mobile app home screen. It shows several book covers. There are some thrillers and some comedies.", scale:1, width:1640, height:1520},
				{type: "image", path: "/images/experience/gallery/readable/1.png", alt: "The Readable mobile app Story screen. The story is 'Dark Noises', with the description: \"Strange noises are coming from the top floor of the construction site\".", scale:1, width:1640, height:1520},
			]
		},


		{
			id: "firetv",
			title: "Amazon Fire TV",
			badges: ["prototype", "amazon"],
			summary: "Rapid prototyping for Amazon Fire TV",
			description: `
				<p>
					I was front-end developer for the prototyping phase of Amazon's 
					new interface for their Fire TV platform.
				</p>
				<p>
					We worked with the Prime Video UXD / UI team, taking their wireframes 
					and mockups and building working prototypes. During rigorous user 
					testing sessions we produced prototype iterations on demand as the UXD 
					team encountered issues their assumptions hadn't predicted.
				</p>
			`,
			roles: "",
			links: [] as LinkData[],
			tech: "JAVASCRIPT, SKETCH, PHOTOSHOP, USER TESTING",
			images: [
				{type: "image", path: "/images/experience/gallery/amazon/0.png", alt: "The prototype Amazon Fire TV interface", scale:1, width:1640, height:882}
			]
		},


		{
			id: "bbcwebwise",
			title: "BBC WebWise",
			badges: ["bafta-learning-nominee", "bima-interactive"],
			summary: `BBC award winning educational interactives`,
			description: `
				<p>
					I was tech lead on four educational interactives that formed the core of the BBC's BAFTA nominated online learning portal.
				</p>

				<ul class="awards">
					<li>The interactives gained the <strong>BBC’s highest Net Promoter score</strong> on bbc.co.uk for 18 consecutive months</li>
					<li>BIMA Interactive Media winner</li>
					<li>BAFTA Interactive Learning nominee</li>
			`,
			roles: "",
			links: [] as LinkData[],
			tech: `AS3, GAME DESIGN`,
			images: [
				{type: "image", path: "/images/experience/gallery/webwise/1.png", alt: "A spooky gothic house and the title of the interactive: 'Cobweb Castle'", scale:1, width:1640, height:906},
				{type: "image", path: "/images/experience/gallery/webwise/3.png", alt: "A computer game where isometric celebrities waddle around a haunted bedroom. There's a horse head in the bed.", scale:1, width:1640, height:906},
				{type: "image", path: "/images/experience/gallery/webwise/4.png", alt: "A computer game where celebs explore a fright-filled garden. A zombie awakens from its crypt.", scale:1, width:1640, height:906},
			]

		},


		{
			id: "bocan",
			title: "Bòcan",
			badges: ["website"],
			summary: `Online resource for Scottish Gaelic language learners`,
			description: `
				<p>
					We were a small team tasked with revitalising this popular Gaelic website. Again, I was tech lead, with main tasks...
				</p>
				<ul>
					<li>Researching technologies</li>
					<li>Conversion of legacy CMS data</li>
					<li>Porting complex Flash content to cross-platform JavaScript</li>
					<li>SEO</li>
					<li>Hosting and domains (Google App Engine)</li>
					<li>UXD simplifying user journeys through the archive</li>
				</ul>
			`,
			roles: "",
			links: [
				{name: "www.bocan.tv", url: "www.bocan.tv"},
			] as LinkData[],
			tech: `JAVASCRIPT, NODE.JS, PHP, SQL, SEO, REACT JS`,
			images: [
				{type: "image", path: "/images/experience/gallery/bocan/0.png", alt: "A website with an illustrated map of Scotland and thumbnails of fun cartoons.", scale:1, width:1640, height:2146},
				{type: "image", path: "/images/experience/gallery/bocan/1.png", alt: "The Bòcan website film page, displaying the cartoon 'Pirate on the Sea Stack'. The cover image of the film is a crêpe paper black skull with green hair and orange eyes.", width:1640, height:1378},
			]

		},


		{
			id: "wordable",
			title: "Wordable",
			badges: ["elton-learners", "mobile-app"],
			summary: `Mobile app that makes vocabulary learning easy, fun, and social`,
			description: `
				<p>
					I was tech lead on this iOS and Android app for language learning which pitted 
					learner against learner in a competitive battle. My main tasks:
					<ul>
						<li>Front-end UI build and business logic</li>
						<li>Back-end app server</li>
						<li>Custom content management tools</li>
						<li>Game design</li>
					</ul>
				</p>
				<p>
					With some great results...

					<ul class="awards">
						<li>Average <b>4.7 stars</b> on Google Play, with over 100K installs</li>
						<li>Finalist in the 2018 <b>ELTon Awards</b> (Innovation in Learner Resources category)</li>
					</ul>
				</p>
			`,
			roles: "TECH LEAD, FRONT-END DEVELOPER, BACK-END DEVELOPER",
			links: [
				{name: "www.playlingo.co", url: "www.playlingo.co"},
			] as LinkData[],
			tech: `ANDROID, IOS, SQLITE, MONGODB, NODE.JS, PHP, HAXE`,
			images: [
				{type: "youtube", url: "https://www.youtube.com/embed/GrznPf15wEo", scale:1, width:853, height: 480, alt: ""},
				{type: "image", path: "/images/experience/gallery/wordable/2.png", scale:1, width:1640, height:1520, alt: "The Wordable mobile app Home screen. The player is currently playing three games with other players in China and Türkiye."},
				{type: "image", path: "/images/experience/gallery/wordable/3.png", scale:1, width:1640, height:1520, alt: "The Wordable spinner screen. The player spins a vibrantly coloured wheel to choose which kind of question to be asked."},
				{type: "image", path: "/images/experience/gallery/wordable/4.png", scale:1, width:1640, height:1520, alt: "The Wordable question screen. The player has to choose which image matches the English word \"orange\"."},
				{type: "image", path: "/images/experience/gallery/wordable/6.png", scale:1, width:1640, height:1520, alt: "The Wordable prizes screen. The player has a prize for answering 4 different types of question correctly."},
			]

		},


		{
			id: "arika",
			title: "Arika",
			badges: ["website"],
			summary: `Online archive for world-renowned arts organisation`,
			description: `
				<p>
					We were tasked with the modernisation and expansion of <b>Arika's</b> web presence. 
					We took it from a legacy Flash brochure-site to a full CMS driven site. 
					At its core is an extensive archive of media-rich documents with faceted search.<br>
					<br>
					My main tasks as tech lead on this project:
					<ul>
						<li>Database design</li>
						<li>Drupal development</li>
						<li>HTML component builds</li>
						<li>Solr search engine management</li>
					</ul>
					
					<ul class="awards">
						<li>
							In 2020 Arika won one of the established art world's <i>most prestigious</i> awards, the <b>Turner Prize</b>.
						</li>
					</ul>
				</p>
			`,
			links: [
				{name: "www.arika.org.uk", url: "www.arika.org.uk"},
			] as LinkData[],
			tech: `JAVASCRIPT, HTML, CSS, DRUPAL, PHP, MYSQL, SOLR`,
			images: [
				{type: "image", path: "/images/experience/gallery/arika/0.png", alt: "The Arika website homepage. We see an archive of many artistic events. The featured event is called \"A Film Is A Statement\".", scale:1, width:1640, height:4450},
			]

		},


		{
			id: "lingopolis",
			title: "Lingopolis",
			badges: ["elton-learners", "web-app"],
			summary: `Language learning web app`,
			description: `
				<p>
					This beautiful project was the first app we made for Playlingo, 
					a startup dedicated to bringing play to language learning.
				</p>
				<p>
					We partnered with <b>Cambridge University Press</b> to create an innovative educational city-building game 
					– as learners make progress they grow their city from hamlet to metropolis.
				
					I was tech lead, with roles...
				</p>
				<ul>
					<li>Game design</li>
					<li>Front-end build</li>
					<li>Back-end content CRUD</li>
				</ul>
				<p>
					The project found significant funding, won recognition in the ESOL community and learner feedback was very positive. 
					Ultimately we had to pivot, but valuable lessons were learned!
				</p>
				<ul class="awards">
					<li>Innovate UK award winner (Innovation in Education)</li>
					<li>Scottish EDGE award winner</li>
					<li>Finalist in the 2016 ELTon Awards (Digital Innovation category)</li>
				</ul>
			`,
			links: [
				{name: "www.playlingo.co", url: "www.playlingo.co"},
			] as LinkData[],
			tech: `JAVASCRIPT, MYSQL, PHP, SYMFONY, HAXE, AGILE`,
			images: [
				{scale:1, width:853, height:480, type: "youtube", url: "https://www.youtube.com/embed/Qnkfz2afibE"},
                {type: "image", path: "/images/experience/gallery/lingopolis/0.png", alt: "The Lingopolis web app. The screen is filled with a cityscape that the player has built up. It looks like Las Vegas if it was in Miami. The player is being asked a question about the English word \"orange\".",scale:1, width:1640, height:1162},
                {type: "image", path: "/images/experience/gallery/lingopolis/1.png", alt: "The promotional website for the Lingopolis app. The headline is \"Play to learn English\", branded with the Cambridge Dictionaries logo.",scale:1, width:1640, height:2036},

			]

		},

		// {
		// 	id: "",
		// 	title: "",
		// 	badges: ["", ""],
		// 	summary: ``,
		// 	description: ``,
		// 	links: [
		// 		{name: "", url: ""},
		// 	] as LinkData[],
		// 	tech: ``,
		// 	images: [
		// 		{type: "image", path: "", alt: "", scale:1, width:1640, height:882},
		// 	]

		// },

	],

	"badges": {
		"url": "/images/common/badges/{filename}",
		"items": {
			"british-council-elton-finalist": {"filename": "elton-eggshell.png", "alt": "British Council ELTon award finalist"},
			"mobile-app": {"filename": "mobile-app-eggshell.png", "alt": "Mobile app"},
			"prototype": {filename: "prototype-eggshell.png", alt: "Prototype"},
			"amazon": {filename: "amazon-eggshell.png", alt: "Amazon"},
			"bafta-learning-nominee": {filename: "bafta-eggshell.png", alt: "Bafta interactive learning nominee"},
			"bima-interactive": {filename: "bima-eggshell.png", alt: "Bima interactive media winner"},
			"elton-learners": {filename: "elton-eggshell.png", alt: "British Council ELTon Learner Innovation finalist"},
			"website": {filename: "website-eggshell.png", alt: "Website"},
			"web-app": {filename: "web-app-eggshell.png", alt: "Web app"},
			// "": {filename: "", alt: "Amazon"},
		}
	}


}
export type Data = typeof data;
export type ProjectData = typeof data.projects[0]
export type AllBadgesData = {[key:string]: BadgeData}
export type BadgeData = {filename:string, alt: string}
export type LinkData = {name:string, url: string}

export type ImageData = {
    // filename:string,o
    width:number,
    height:number,
	type?:string,
    scale?:number,
	// Video url
	url?:string,  
	path?:string,  
	alt?:string,  
}

export default data;

// import Lang, {LangId} from '../tools/Lang'


enum EnvironmentId {
    dev = "dev",
    prod = "prod"
}


export default class Tools{


    static get isDev():boolean{return this.environment === EnvironmentId.dev}
    static get isProd():boolean{return this.environment === EnvironmentId.prod}
    


    static scrollIntoView(id:string, smooth:boolean = true){
        // console.log("Tools.scrollIntoView()");
        // console.log(" - id = " + id)
        // console.log(" - smooth = " + smooth)

        const behaviour = smooth ? 'smooth' : 'auto';
        let el = document.getElementById(id);
        if(!el) return
        if(Tools.isSafari){
            const targetY = window.scrollY + el.getBoundingClientRect().top;
            window.scrollTo({ top: targetY, left: 0, behavior: behaviour });
        }
        
        else{
            el.scrollIntoView({behavior: behaviour});
        }

    }

    static localToGlobal( _el:any ):Object {
            var target = _el,
            target_width = target.offsetWidth,
            target_height = target.offsetHeight,
            // target_left = target.offsetLeft,
            // target_top = target.offsetTop,
            gleft = 0,
            gtop = 0,
            rect = {};
    
            var moonwalk = function( _parent:any ) {
            if (!!_parent) {
                gleft += _parent.offsetLeft;
                gtop += _parent.offsetTop;
                moonwalk( _parent.offsetParent );
            } else {
                return rect = {
                top: target.offsetTop + gtop,
                left: target.offsetLeft + gleft,
                bottom: (target.offsetTop + gtop) + target_height,
                right: (target.offsetLeft + gleft) + target_width
                };
            }
        };
            moonwalk( target.offsetParent );
            return rect;
    }

    static getElementYRelativeToViewport(el:any):number{
        if(!el) return 0;
        var viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        return viewportOffset.top;
        // var left = viewportOffset.left;
    }

    /**
     * We consider ONLY a local server on port 3000 to be dev.
     */
    static get environment():EnvironmentId{
        const isDev = this.isLocal && (window.location.port === "3000" || window.location.port === "5000");
        return isDev ? EnvironmentId.dev : EnvironmentId.prod;
    }

    /**
     * Is the site deployed on remote server, e.g. production site at https://darri.appspot.com
     */
    static get isDeployedProduction():boolean{
        return  (this.isProd && !this.isLocal)
    }
    /**
     * Either production or dev server running on localhost or LAN
     */
    static get isLocal():boolean{
        return (
            window.location.href.indexOf("localhost") >= 0 || 
            window.location.href.indexOf("192.168") >= 0
        );
    }

    static get isSafari() {
        let isSafari = false;
        try{
            isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
        }catch(err){}
        return isSafari;
      
      }
    /**
     * Is the user accessing the site on a LAN? 
     */
    static get isLAN():boolean{
        return window.location.href.indexOf("192.168") >= 0
    }


    static object = {

        /**
         * Get the number of keys in an Object
         * @param obj
         * @returns Total number of keys
         */
        numKeys(obj:Object) {
            let num:number = 0;
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) num++;
            }
            return num;
         }
    }
    
     static array = {

        /**
         * Sort array by key
         * @param {Array} arr
         * @param {string} key 
         * @param {boolean} [desc]
         */
         sortByKey(arr:any[], key:string, desc:boolean = false):any[]{
            arr.sort(function(a, b){
                if(a[key] < b[key]){
                    return desc ? 1 : -1;
                    // return -1;
                }else if(a[key] > b[key]){
                    return desc ? -1 : 1;
                    // return 1;
                }
                return 0;
            });
            return arr;
        }
    }

    static fillTags(template:string, values:any, openChar:string="{", closeChar:string="}") {
		for (let key in values) {
			let value = values[key]
			// Replace "{key}" with "value"
			var reg = new RegExp(`${openChar}[\\s]*${key}[\\s]*${closeChar}`, "gi")
			template = template.replace(reg, value)
		}
		return template;
	}

	/**
	 * Populate any tags in a template string with the one value.
	 * 
	 * @param {string} template 
	 * @param {any} value 
	 */
	static fillTagsWithValue(template:string, value:any) {
		template = template.replace(/{\s*(.*?)\s*}/gi, value)
		return template;
	}


    /**
     * Truncate text string. This method leaves words intact.
     * Inspired by https://stackoverflow.com/questions/4700226/i-want-to-truncate-a-text-or-line-with-ellipsis-using-javascript
     * @param str 
     * @param limit 
     * @returns
     */
    static trunc (str:string, limit:number):string {
        const symbol:string = '…';
        if (str.length > limit){
            for (let i = limit; i > 0; i--){
                if(str.charAt(i) === ' ' && (str.charAt(i-1) !== ','||str.charAt(i-1) !== '.'||str.charAt(i-1) !== ';')) {
                    return str.substring(0, i) + symbol;
                }
            }
             return str.substring(0, limit) + symbol;
        }
        
        return str;
    };


    /**
     * Does a basic detection to find out if user is on touch device
     * @returns {boolean}
     */
     static get isTouchDevice():boolean{
        
        // METHOD 1
        // https://code-examples.net/en/q/3ca6ab
        // @ts-ignore
        // return true === ("ontouchstart" in window || (window.DocumentTouch && document instanceof DocumentTouch));

        // METHOD 2
        // https://stackoverflow.com/questions/15221680/javascript-detect-touch-devices
        // @ts-ignore
        // return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||(navigator.msMaxTouchPoints > 0));

        // METHOD 3
        // Uses media queries. window.matchMedia is well supported at 97.64% (https://caniuse.com/?search=matchmedia)
        // https://css-tricks.com/touch-devices-not-judged-size/
        const isPointerCoarse:boolean = window.matchMedia("(pointer: coarse)").matches;
        const isHover:boolean = window.matchMedia("(hover: hover)").matches;
        const isTouchDevice:boolean = isPointerCoarse && !isHover;
        // console.log(" - isPointerCoarse = " + isPointerCoarse)
        // console.log(" - isHover = " + isHover)
        // console.log(" - isToucDevice = " + isTouchDevice)
        
        return isTouchDevice;
    }

    /**
     * Is device capable of hover interaction, e.g. mouse.
     * This can be used to distinguish between mobile and desktop devices.
     */
    static get isHoverDevice():boolean{
        // Uses media queries. window.matchMedia is well supported at 97.64% (https://caniuse.com/?search=matchmedia)
        // https://css-tricks.com/touch-devices-not-judged-size/
        const isHover:boolean = window.matchMedia("(hover: hover)").matches;
        return isHover;
    }


    /**
     * Split a string into an array of strings based on line-breaks. Used for splitting a long screed into paragraphs
     * @param str 
     * @param trimWhitespace 
     * @param removeEmpty 
     * @returns Array of strings
     */
    static stringToArray(str:string, trimWhitespace:boolean = true, removeEmpty:boolean = true):string[]{
        if(!str) return [];

        // Split string by line breaks
        let arr:string[] = str.split(/\r?\n/g);
        
        for(let i = arr.length-1; i >= 0; i--){
            str = arr[i];

            // Trim
            if(trimWhitespace) arr[i] = arr[i].trim();
            
            // Remove if empty
            if(removeEmpty && !arr[i]) arr.splice(i, 1)
        }
        if(!arr) arr = [];
        return arr;
    }

    /**
     * Trims the junk off of the end of a string, e.g. "," "." ";"
     * https://stackoverflow.com/questions/661305/how-can-i-trim-the-leading-and-trailing-comma-in-javascript
     * @param str 
     * @returns string
     */
    static stringTrimTrailingJunk(str:string):string{
        // return str.replace(/(^,)|(,$)|(.$)/g, "");
        str = str.replace(/(^[,\s]+)|([,\s]+$)/g, '');
        str = str.replace(/(^[.\s]+)|([.\s]+$)/g, '');
        str = str.replace(/(^[;\s]+)|([;\s]+$)/g, '');
        return str;
    }

    static stringCapitalizeFirstLetter(str:string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    static later(secs:number):Promise<void> {
		return new Promise((resolve:Function) => setTimeout(resolve, secs*1000));
	}

    static arrayRandomElement(arr:Array<any>):any {
		if (!arr || arr.length === 0) return undefined;
		let i = Math.floor(Math.random() * arr.length)
		let el = arr[i]
		return el;
	}

    static stringClean(str:string):string{
        str = str.replace(/<\s*\/?div\s*>/gi, "");
        str = str.replace(/<\s*br\s*\/?\s*>/gi, "\n");
        str = str.replace(/&amp;/gi, "&");
        str = str.replace(/&nbsp;/gi, " ");
        str = str.replace(/&lt;/gi, "<");
        str = str.replace(/&gt;/gi, ">");
        
        // console.log("cleaned string = ", str)
        return str;
    }

   
    static addClassToHTML(str:string){
        const list = document.getElementsByTagName('html')[0].classList;
        // log("list = ", list);
        list.add(str);
    }

    static removeClassFromHTML(str:string){
        const list = document.getElementsByTagName('html')[0].classList;
        // log("list = ", list);
        list.remove(str);
    }

    /**
     * Replace diacritics with latin version of string.
     * @see https://stackoverflow.com/questions/863800/replacing-diacritics-in-javascript
     * @param str 
     * @returns {string}
     */
    static stringNormalize(str:string):string{
        // console.log("Tools.stringNormalize()");
        // console.log("str = " + str);
        str = str.normalize('NFKD').replace(/[^\w]/g, '');
        // console.log("str = " + str);
        return str;
    }
    
    
	static poll(func:() => boolean, intervalSecs:number=1, timeout:number=-1):Promise<void> {
		return new Promise((resolve, reject)=> {
			const interval = setInterval(() => {
				let isComplete = func()
				if (isComplete) {
					clearInterval(interval)
					resolve()
				}
			}, intervalSecs*10000)
		});
	}
}



